// @media (min-width:320px)  { /* smartphones, iPhone, portrait 480x320 phones */ }
// @media (min-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
// @media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
// @media (min-width:961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
// @media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
// @media (min-width:1281px) { /* hi-res laptops and desktops */ }

// Diminuito il min-width 992 per adattarci alla large di bootstrap
@media screen and (min-width: 992px) and (max-width: 1200px) {
  #login {
    #container01 {
      padding: unset !important;

      #second {
        img {
          margin-left: auto;
          margin-right: auto;
          display: block;
        }
      }
    }

    #container02 {
      padding: unset !important;

      #first {
        .divCarouselContainer {
          height: auto !important;

          .divItem {
            position: static !important;
          }
        }

        .carouselText {
          left: unset !important;
        }
      }
    }

    #container04 {
      height: unset !important;
      padding: unset !important;
      padding-top: 60px !important;
      padding-bottom: 60px !important;
      text-align: center;

      img {
        margin-top: 40px;
      }
    }

    #container05 {

      &::before {
        display: none !important;
      }

      #second {
        padding: unset !important;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    #questionari .card-questionari-cognitivi {
      max-width: 465px !important;
    }

    #container02 {
      #first {
        .divCarouselContainer {
          height: auto !important;

          .divItem {
            position: static !important;
            text-align: center;
          }
        }

        .carouselText {
          left: unset !important;
        }
      }
    }

    #container05 {
      #second {
        padding-left: 150px !important;
        padding-right: 150px !important;
      }
    }

    #moduli-dettaglio {
      .card-moduli {
        max-width: 440px !important;
      }
    }
  }
}
