// @media (min-width:320px)  { /* smartphones, iPhone, portrait 480x320 phones */ }
// @media (min-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
// @media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
// @media (min-width:961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
// @media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
// @media (min-width:1281px) { /* hi-res laptops and desktops */ }

// Aumentato il min-width 576 per adattarci alla small di bootstrap
// Diminuito il max-width 992 per adattarci alla large di bootstrap
@media screen and (min-width: 576px) and (max-width:992px) {
  body {

    .resizableDialog {
      max-height: 100% !important;
      width: 100% !important;
      height: 100% !important;
    }

    .bacheca {
      width: 100%;
      min-width: unset;
    }

    #login {
      #header {
        padding: 20px 0 20px;

        &.active-scroll {
          padding: 20px !important;

          .mobile-button {
            box-shadow: none;
          }
        }
      }

      #container00 {
        .wrap-info {
          background-color: #fff;
          padding: 50px;
          border-radius: 16px;
          box-shadow: 0 3px 15px rgb(0 0 0 / 20%);
        }
      }
    }

    #main-nav {
      display: none !important;
    }

    #main-body {
      margin-left: 0 !important;

      #main {
        padding: 40px 15px !important;
      }

      #main.docente {
        #studente {
          .scuole-successo {
            .wrap-title-successo {
              .title {
                width: unset;
              }
            }

            .cards {
              border-left: unset;
              border-top: 2px solid $fut-grey-neutral;
              margin: 24px 0;
              padding: 28px 0 0;

              .card-success {
                max-width: unset;
                width: 100%;
                padding-right: unset;
              }
            }
          }
        }
      }
    }

    #second-nav {
      padding: 12px 15px !important;

      .mobile-button {
        display: inline-block !important;
      }
    }

    #login {
      #container01 {
        padding: unset !important;

        #second {
          img {
            margin-left: auto;
            margin-right: auto;
            display: block;
          }
        }
      }

      #container02 {
        padding: unset !important;

        #first {
          .divCarouselContainer {
            height: auto !important;

            .divItem {
              position: static !important;
            }
          }

          .carouselText {
            width: unset !important;
            left: unset !important;
          }
        }
      }

      #container04 {
        height: unset !important;
        padding: unset !important;
        padding-top: 60px !important;
        padding-bottom: 60px !important;
        text-align: center;

        img {
          margin-top: 40px;
        }
      }

      #container05 {

        &::before {
          display: none !important;
        }

        #second {
          padding: unset !important;
        }

        .wrap-video {
          margin-top: 25px;
          padding: 0;
        }
      }

      .padding {
        padding: 25px 50px;
      }

      #footer {
        .linkFooter:first-of-type {
          border-left: 1px solid white;
        }
      }
    }

    #registrazione-docente,
    #registrazione-studente {
      #header.active-scrool {
        padding: 30px 0;

        .logo {
          display: block;
          margin: 0 auto;
        }
      }

      #main {
        .container-fluid {
          padding-left: 0 !important;
          padding-right: 0 !important;

          .p-card {
            width: 100% !important;
            margin: 0 !important;
          }
        }
      }

      #footer {
        padding: 2rem 2rem 0rem 2rem;

        .logo-fondazione-agnelli {
          margin-left: 2.875rem;
        }
      }
    }

    #moduli-orientativi {
      .card-moduli {
        width: 75%;

        .wrap-text {
          margin-right: 23%;

          .titolo {
            font-size: 1rem;
          }
        }

        .immagine {
          right: -20%;
          width: 50%;
        }
      }
    }

    #moduli-dettaglio {
      .header-classe {
        .extra-div {
          right: 50px;
          width: 33%;
        }
      }
    }

    #questionari-dashboard {
      .card-questionari-dashboard {
        .wrap-quest {
          .parallelepipedo {
            -webkit-transform: unset;
            transform: unset;
          }

          .quest {
            /* &:not(:first-child) {
              margin-top: 15px;
            } */
            margin-bottom: 15px;
          }
        }
      }
    }

    #moduli-dashboard {
      .card-contribuisci {
        min-height: unset;

        .wrap-content {
          button {
            position: inherit;
            width: 100%;
            margin-top: 12px;
          }
        }
      }
    }

    #percorso {
      .attivita {
        .questionari {
          margin-bottom: 0;
        }

        .questionari-skeleton {
          .left-info {
            .extra-1 {
              margin-bottom: 20px;

              img {
                margin: 0 auto;
                display: block;
              }
            }

            .info {
              display: block;
              margin: 0 auto;
              text-align: center;
            }
          }

          .extra-2 {
            display: none;
          }
        }
      }

      .wrap-percorso {
        width: 100%;

        .wrap-raod {
          width: 100%;
          min-height: 55px;
          background-size: auto;
          margin: 0 auto;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          .pin {
            position: initial;
            display: inline-block;
            margin: 0 15px 0 0;

            &.fine {
              display: block;

              img {
                width: 50px;
              }
            }

            &.fine.active-fine img {
              padding: 5px;
            }
          }

          .road {
            display: none;
          }
        }

      }

      .scuole-successo {
        .wrap-title-successo {
          .title {
            width: unset;
          }
        }

        .cards {
          border-left: unset;
          border-top: 2px solid $fut-grey-neutral;
          margin: 24px 0;
          padding: 28px 0 0;

          .card-success {
            max-width: unset;
            width: 100%;
            padding-right: unset;
          }
        }
      }

      .giudizio-orientativo {
        .wrap-titolo {
          .titolo {
            width: unset;
          }

          border-right: unset;
          padding-bottom: 20px;
          border-bottom: 2px solid #CED6E2;
        }

        .content-giudizio {
          margin: 30px 0;
        }

        .note-docente {
          border-left: unset;
          max-width: unset;
          min-height: unset;
          padding: 0;
        }
      }
    }

    #esprimi-preferenze {
      .secondary-header {
        padding: 15px 20px;
      }

      .content {
        padding: 0;

        .piacerebbe-frequentare {
          .valutazione-interesse {
            .wrap-scuole-interesse {
              .wrap-scuola {
                padding: 24px 16px;

                .domanda {
                  width: 100%;
                  margin-bottom: 24px;
                }

                .wrap-valutazione {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }

    #main.docente #studente,
    #percorso {
      .preferenza {
        .wrap-preferenza {
          .wrap-titolo {
            border-right: 0;
            margin: 0 0 24px 0;

            .titolo {
              width: 100%;
            }
          }

          .content {
            width: 100%;
            max-width: unset !important;
            margin: 0;
            padding: 28px 0 0;
            border-top: 2px solid $fut-grey-neutral;
          }

          .buttons {
            margin: 24px 0 0;
          }
        }
      }

      .giudizio-orientativo {
        .wrap-titolo {
          .titolo {
            width: unset;
          }

          border-right: unset;
          padding-bottom: 20px;
          border-bottom: 2px solid #CED6E2;
        }

        .content-giudizio {
          margin: 30px 0;
        }

        .note-docente {
          border-left: unset;
          max-width: unset;
          min-height: unset;
          padding: 0;
        }
      }
    }

    #svolgi-questionario .questionario .content-domanda .domanda {
      max-width: 100%;
      width: 100%;
    }
  }
}
