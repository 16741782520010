/* You can add global styles to this file, and also import other style files */
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "~katex/dist/katex.css";
@import "./styles/fonts";
@import "./styles/variables";
@import "./styles/mobile";
@import "./styles/tablet";
@import "./styles/other-screen";
@import "./styles/toastr";
@import "./styles/theme";

/* Formatter =================================================== */
body {
  margin: 0;
  padding: 0;
  color: $fut-blue;
  background: $bg-color;
  font-family: Poppins;
  font-size: 0.875rem;
  text-decoration: none;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.overflow {
  overflow: hidden;
}

a,
a:hover {
  text-decoration: none;
  cursor: pointer;
}

a:focus,
a:active,
button:focus,
button:active {
  outline: 0;
}

button:hover,
button:focus {
  outline: 0;
}

.clear {
  clear: both;
}

h1 {
  color: $fut-blue;
  font-weight: 700;
  font-family: Space-Grotesk;
  // font-size: 2rem;
}

h2 {
  color: $fut-blue;
  font-weight: 700;
  font-family: Space-Grotesk;
  // font-size: 1.5rem;
}

h3 {
  color: $fut-blue;
  font-weight: 700;
  font-family: Space-Grotesk;
  // font-size: 1.125rem;
}

h4 {
  color: $fut-blue;
  font-weight: 700;
  font-family: Space-Grotesk;
  // font-size: 1rem;
}

h5 {
  color: $fut-blue;
  font-weight: 700;
  font-family: Space-Grotesk;
  // font-size: 0.875rem;
}

h6 {
  color: $fut-blue;
  font-weight: 700;
  font-family: Space-Grotesk;
  // font-size: 0.75rem;
}

p {
  color: $fut-blue;
  margin-bottom: 0.5rem;
}

.bold600 {
  font-weight: 600;
}

.bold700 {
  font-weight: 700;
}

.p-relative {
  position: relative !important;
}

.p-absolute {
  position: absolute !important;
}

input {
  border: 1px solid $input-color;
  border-radius: 1rem;
}

input:focus-visible {
  outline: unset;
}

label {
  font-size: 0.875rem;
  color: $fut-blue;
  margin-bottom: 0.25rem;
  display: block;
}

.fieldset {
  margin-bottom: 2rem;
}

.span-label {
  font-size: 0.875rem;
  color: $fut-blue;
  margin-bottom: 5px;
}

.bg-grey {
  background-color: $bg-grey;
}

.text-orange {
  color: $fut-orange;
}

.text-orange:hover {
  color: $fut-orange;
}

.text-aquamarine {
  color: $ico-aquamarine;
}

.text-bold-orange {
  font-weight: 700;
  font-size: 20px;
  color: $fut-orange;
}

.divIco {
  width: 60px;
  height: 60px;
  background-color: white;
  display: table;
  border-radius: 20px;
  box-shadow: -5px 10px 30px $shadow-color;

  i {
    font-size: 24px;
    color: $fut-blue;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }
}

.divIco.selected {
  background: linear-gradient(white 0 0) padding-box,
    /*this is your grey background*/
      linear-gradient(to left, $fut-orange, rgba(0, 0, 0, 0)) border-box;
  border: 3px solid transparent;
  border-radius: 15px;
}

.divIco.notification {
  position: relative;

  span {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $fut-orange;
    border-radius: 50%;
    padding: 8px;
  }
}

.field-checkbox {
  margin-bottom: 2rem;
}

#loginModal {
  padding-left: 1rem;
  padding-right: 1rem;
}

.loginInput:first-of-type {
  margin-top: unset;
  margin-bottom: 40px;
}

.loginInput {
  margin-top: 40px;
  margin-bottom: 20px;
}

.registration {
  background-color: $bg-grey;
}

.divCheck {
  display: flex;
  margin-top: 1.5rem;

  p {
    margin-top: 5px;
    margin-left: 15px;
    font-size: 14px;
  }
}

.logoCarousel {
  margin-top: 60px;
  margin-bottom: 60px;
}

.titleRed {
  color: $redError;
}

.titleGreen {
  color: $greenSuccess;
}

#container02 {
  .p-carousel .p-carousel-indicators .p-carousel-indicator button {
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }

  .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background-color: black;
  }

  .p-button.p-button-warning:enabled,
  .p-buttonset.p-button-warning > .p-button:enabled,
  .p-splitbutton.p-button-warning > .p-button:enabled {
    width: 195px;
  }

  .p-button-label {
    font-size: 1rem;
  }
}

.w-100 {
  width: 100%;
}

.katex {
  font-family: Poppins !important;
  font-size: 1rem;
}

// ScrollBar ===================================================

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $fut-orange;
  background-clip: padding-box;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:horizontal {
  border-top: 0;
  border-bottom: 0;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  // background: rgba(0, 0, 0, 0.3);
  background-clip: padding-box;
}

// Spinner ===================================================
.loading-overlay {
  position: fixed;
  z-index: 10001;
  background: rgba(249, 249, 249, 0.25);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .wrap-spinner {
    width: 100%;
    text-align: center;
    position: absolute;
    z-index: 10005;
    top: 43.5%;
  }
}

.lds-facebook {
  width: 64px;
  height: 64px;
  margin: 0 auto;
  display: inline-block;
  text-align: center;
  position: relative;
}

.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 6px;
  width: 13px;
  background: #192b2f;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(1) {
  left: 6px;
  animation-delay: -0.24s;
}

.lds-facebook div:nth-child(2) {
  left: 26px;
  animation-delay: -0.12s;
}

.lds-facebook div:nth-child(3) {
  left: 45px;
  animation-delay: 0;
}

@keyframes lds-facebook {
  0% {
    top: 6px;
    height: 51px;
  }

  50%,
  100% {
    top: 19px;
    height: 26px;
  }
}

// Dialog Registrazione ===================================================
.dialog-registrazione.p-dialog {
  &::before {
    content: "";
    background: url("../src/assets/images/bg-popup.svg") no-repeat left top;
    display: block;
    width: 214px;
    height: 56px;
    position: absolute;
    z-index: -1;
    left: 55%;
  }

  background: $bg-dialog-grey;
  overflow: hidden;
}

.modal-login {
  &::before {
    right: 15px;
  }

  .p-dialog-header {
    background: $white;
    border-top-right-radius: $border-radius;
    border-top-left-radius: $border-radius;
  }

  .p-dialog-content {
    background: $white;

    .pi {
      z-index: 1;
    }

    /*     .p-password {
      width: 100%;

      .p-inputtext {
        padding-left: 2.188rem;
      }
    } */

    .fieldset-password {
      position: relative;

      input {
        height: 43px;
        border: 1px solid $fut-grey-neutral;
        padding-left: 40px;
        padding-right: 50px;
      }

      .wrap-password {
        position: relative;

        .lock-password {
          position: absolute;
          left: 0.8rem;
          top: 0.9rem;
        }

        .eye {
          position: absolute;
          right: 0.813rem;
          top: 0.3rem;
          font-size: 24px;
          cursor: pointer;
        }
      }
    }
  }

  .p-dialog-footer {
    border: 0;
    text-align: left;
    background-color: $neutral-grey;
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;

    .footer-login {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
}

// Conferma Dialog ===================================================
.conferma-dialog.p-dialog {
  &::before {
    content: "";
    background: url("../src/assets/images/bg-conferma-popup.svg") no-repeat left
      top;
    display: block;
    width: 275px;
    height: 200px;
    position: absolute;
    z-index: -1;
    left: 0;
  }

  background: $bg-dialog-grey;
  overflow: hidden;
}

// Materie Registrazione ===================================================
#info-aggiuntive {
  form {
    &.ng-dirty {
      .ng-invalid {
        .p-inputtext {
          border-color: $tertiary;
        }

        .p-dropdown {
          border-color: $tertiary;
        }
      }
    }
  }
}

#info-aggiuntive,
#aggiungi-avatar,
#registrazione,
#completa-registrazione,
#configura-classe,
#contenuti,
#promozione-classe {
  #materie {
    white-space: nowrap;

    .p-carousel-item {
      flex: 0 0 0 !important;
      margin-right: 0.375rem;
    }

    .p-carousel .p-carousel-content {
      .p-carousel-prev,
      .p-carousel-next {
        margin: 1px;
      }
    }

    .selected {
      .p-chip {
        color: $white;
        background-color: $fut-blue;
      }
    }
  }

  #materie-selected {
    .p-chip {
      position: relative;
      cursor: default;
      padding: 0 1rem 0 0;
      border: none;

      .p-chip-text {
        margin-left: 1.75rem;
      }

      .pi-chip-remove-icon {
        cursor: pointer;
        position: absolute;
        margin-left: 0px;
        font-size: 1.125rem;
        background-image: url("assets/images/times-square-Regular.svg");
        height: 20px;
        width: 20px;
      }
    }
  }

  //
  .section-consenso {
    p-checkbox {
      vertical-align: top;
      align-items: flex-start;
      margin-right: 0.75rem;
    }
  }

  .p-button-outlined {
    &.undo {
      padding: 0.6rem;

      .pi {
        font-size: 1.2rem;
      }
    }
  }
}

// Dialog Registrazione ===================================================

.dialog-registrazione.p-dialog {
  &::before {
    content: "";
    background: url("assets/images/bg-popup.svg") no-repeat left top;
    display: block;
    width: 214px;
    height: 56px;
    position: absolute;
    z-index: -1;
    left: 55%;
  }
}

// DOCENTE ===================================================
#main-nav {
  width: 220px;
  height: 100%;
  background: $bg-main-nav;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
}

#main-body {
  margin-left: 220px;
  background: $bg-main-body;
  z-index: 0;

  #main {
    padding: 40px 0;
    min-height: calc(100vh - 110px);

    #secondary-header {
      padding: 0 45px;
    }

    .content {
      display: block;
      padding: 0 45px;
      margin: 20px 0 40px;
    }
  }
}

.overlay-profile {
  right: 0 !important;
  width: 100%;
  max-width: 180px;
}

// Profilo Docente - Caricamento Liberatoria ===================================================
#profilo-docente,
#profilo-studente,
#aggiungi-studente-dialog {
  .area-liberatoria {
    border: none;
    position: relative;

    .upload-liberatoria {
      .p-fileupload-buttonbar {
        padding: 20px 0;
        border: none;
      }

      .p-fileupload-content {
        border: 3px dashed $input-color;
        padding: 3px;
        border-radius: 10px;
        height: 120px;
        color: $fut-blue;
        font-size: 0.875rem;
        position: relative;
        transition: 0.3s ease-in-out;
        -webkit-transition: 0.3s ease-in-out;

        &::before {
          content: "";
          background: url("assets/images/bg-liberatoria-left.svg") no-repeat
            left top;
          width: 70px;
          height: 58px;
          position: absolute;
          z-index: 0;
          left: 0;
          bottom: 0;
        }

        &::after {
          content: "";
          background: url("assets/images/bg-liberatoria-right.svg") no-repeat
            left top;
          width: 71px;
          height: 31px;
          position: absolute;
          z-index: 0;
          right: 0;
          top: 0;
        }

        &.p-fileupload-highlight {
          border-color: $fut-orange;

          & > .wrap-text {
            opacity: 0;
          }
        }

        .p-messages {
          position: absolute;
          width: 99.5%;
        }
      }

      .p-fileupload-files {
        padding: 5px;
        color: $fut-blue;
        font-size: 1.125rem;
        display: block;
        height: 100%;

        .ng-star-inserted {
          display: block;
          height: 100%;
        }
      }

      .p-fileupload
        .p-fileupload-buttonbar
        .p-button.p-fileupload-choose.p-focus {
        outline: 0;
        outline-offset: 0;
        box-shadow: none;
      }

      .p-button {
        background-color: $fut-orange;
        outline: 0;
        border: none;
        font-size: 0.875rem;

        &:hover {
          background-color: $fut-orange;
          outline: 0;
          border: none;
        }

        &:focus {
          box-shadow: none;
          outline: 0;
          border: none;
        }

        &:active {
          box-shadow: none;
          outline: 0;
          border: none;
        }
      }

      .wrap-text {
        padding: 10px;
        height: 100%;

        .text {
          display: inline-block;
          color: $fut-blue;
          font-size: 0.875rem;
          font-weight: 400;
          text-align: center;
          vertical-align: middle;

          .plus-icon {
            display: inline-block;
            font-size: 1.219rem;
            vertical-align: middle;
          }

          span {
            color: $fut-orange;
            font-weight: 700;
          }
        }
      }
    }
  }
}

button.link-special {
  margin: 0;
  border: 0;
  padding: 0;
  outline: 0;
  background: none;
  color: $fut-orange;
  font-weight: 500;
}

/* #classi-dashboard {
  .classi-carousel {
    .p-carousel-content {
      .p-carousel-items-content {
        .p-carousel-item {
          flex: 1 0 20%;
        }
      }
    }
  }
} */

.futuri-button-link {
  margin: 0;
  outline: 0;
  border: 0;
  background: none;
  color: $fut-orange;
  font-weight: 500;

  ::first-letter {
    text-transform: capitalize;
  }

  span {
    vertical-align: middle;
  }

  .icon-futuri-link {
    margin-left: 5px;
  }
}

#configura-classe {
  .divider-custom {
    .p-divider-content {
      background-color: $bg-dialog-grey;
    }
  }

  .step {
    .footer-steps {
      .undo {
        min-width: 45px;
      }

      .continua {
        min-width: 142px;
      }
    }
  }
}

#questionari {
  .tabview-custom {
    span.icon {
      font-size: 1.4rem;
      color: $fut-orange;
      vertical-align: middle;
    }

    img.lamp-icon {
      margin-right: 5px;
      width: 16px;
      height: 22px;
      display: inline-block;
    }

    img.questionnaire-icon {
      margin-right: 5px;
      width: 24px;
      height: 22px;
      display: inline-block;
    }

    span {
      vertical-align: middle;
    }
  }
}

#aggiungi-studente-dialog {
  .header-studente-dialog {
    .add-studente {
      .p-inputnumber {
        .p-inputtext {
          height: 40px;
          margin-bottom: 0;
          text-align: center;
        }
      }
    }
  }
}

#registrazione-footer {
  .undo {
    padding: 0.6rem;

    .pi {
      font-size: 1.2rem;
    }
  }
}

// STUDENTE ===================================================

#main {
  background: $bg-main-body;
  padding: 40px 0;
  z-index: 0;

  .content {
    display: block;
    padding: 0 45px;
    margin: 20px 0 40px;
  }

  .haeder-page {
    display: block;
    padding: 0 45px;
  }

  &.svolgi {
    background: $bg-svolgi;
    min-height: calc(100vh - 119px);

    &::after {
      content: "";
      background: url("../projects/studente/src/assets/images/bg-prova.svg")
        no-repeat left top;
      width: 282px;
      height: 679px;
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 0;
    }
  }
}

#codice-studente {
  /* Change the white to any color */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
}

#raccontaci {
  #body {
    .p-calendar-w-btn .p-inputtext {
      width: 274px;
    }

    .p-password {
      width: 312px;
    }
  }
}

// OVERLAY YOUTUBE PLAYER ===================================================

.p-sidebar.youtubeVideo {
  position: fixed;
  background: rgba(0, 0, 0, 0.8);

  .p-sidebar-content {
    height: 100%;
  }

  .p-sidebar-header {
    .p-sidebar-close {
      color: $white;

      &:hover {
        color: $white;
      }
    }
  }
}

#contenuti {
  .wrap-card {
    .p-carousel .p-carousel-content {
      .p-carousel-prev,
      .p-carousel-next {
        margin: 1px;
      }
    }
  }
}

#risultati-modulo {
  .p-tabview .p-tabview-panels {
    background: transparent;
    padding: 1.25rem 0;
    border: 0 none;
    color: #0e2e66;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

// angular-gauge
#gauge-valutazione {
  mwl-gauge {
    display: block;
  }

  mwl-gauge > .gauge > .dial {
    stroke: $bg-neutral-grey;
    stroke-width: 5;
    border: 10px;
  }

  mwl-gauge > .gauge > .value {
    stroke: #706cff;
    //stroke-dasharray: 38 5;
    stroke-width: 5;
  }

  mwl-gauge > .gauge > .value-text {
    fill: $fut-blue;
    font-size: 0.7em;
  }
}

#completa-modulo-dialog {
  .p-checkbox {
    margin-left: 15px;
    margin-right: 10px;
  }
}

#registrazione-docente {
  .p-card {
    margin-top: 40px;
  }
}

#registrazione-docente,
#registrazione-studente {
  p-progressbar {
    .p-progressbar {
      margin: 12px 10px;
    }
  }
}

// BACHECA STUDENTE ===================================================
.bacheca {
  border: 2px solid #e9eff7;
  box-shadow: 0px 12px 24px rgba(184, 184, 184, 0.3);
  border-radius: 16px 0px 0px 0px;
  min-width: 420px;

  &:before {
    content: "";
    width: 149px;
    height: 36px;
    background: url("../projects/docente/src/assets/images/vector-bacheca.svg")
      no-repeat left top;
    position: absolute;
    left: 30%;
  }
}

.paginator-questionari {
  justify-content: space-between !important;
  background: transparent;
  position: fixed;
  // width: 100%;
  bottom: 10%;
  right: 0;
  user-select: none;

  .p-paginator-next,
  .p-paginator-prev {
    width: 60px;
    height: 90px;
    background-color: #f48756;
    color: #fff;
    border: 0;
    border-radius: 5px;
  }

  .p-paginator-prev {
    display: none;
  }

  .p-paginator-next {
    .p-paginator-icon {
      font-size: 50px;
    }
  }

  .p-paginator-next:not(.p-disabled):not(.p-highlight):hover {
    background: #ff5000;
    color: #fff;
    border: 0;
  }
}

.contenuti-chips {
  .p-dialog-content {
    .chips {
      margin-bottom: 15px;

      span {
        padding: 5px 10px;
        background-color: $bg-neutral-grey;
        color: #626f87;
        border-radius: 5px;
      }
    }
  }
}

#cookie-policy {
  font-family: Poppins;

  h1 {
    font-family: Poppins;
  }
}

.dropdown-dialog-custom {
  &.p-dropdown {
    .p-dropdown-panel {
      max-width: 100%;

      .p-dropdown-item {
        white-space: unset;
      }
    }
  }
}

.p-accordion.custom-accordion {
  .p-accordion-header {
    .p-accordion-header-link {
      .p-accordion-toggle-icon {
        display: none;
      }
    }
  }

  .p-accordion-tab:not(.p-accordion-tab-active) {
    .p-accordion-header {
      .p-accordion-header-link {
        border: 0;
      }
    }
  }

  .p-accordion-tab {
    .p-accordion-content {
      padding: 1.25rem 0;
    }
  }
}

.custom-slider-giudizio {
  opacity: 1;

  .p-slider-handle {
    visibility: hidden;
  }

  .p-slider-range {
    background: linear-gradient(90deg, #efdd77 0%, #fba984 100%);
  }
}

// FAKE SIDEBAR
.fake-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;

  .button-close {
    margin: 10px;
    display: flex;
    justify-content: end;

    .close {
      background: transparent;
      border: 0;
      padding: 10px;
      font-size: 16px;
      color: #fff;
      border-radius: 24px;
      line-height: 0;
    }
  }
}

// moduli-carousel
.moduli-carousel {
  .p-carousel-content {
    .p-carousel-items-content {
      .p-carousel-items-container {
        .p-carousel-item {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
