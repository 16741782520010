// @media (min-width:320px)  { /* smartphones, iPhone, portrait 480x320 phones */ }
// @media (min-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
// @media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
// @media (min-width:961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
// @media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
// @media (min-width:1281px) { /* hi-res laptops and desktops */ }

// Aumentato il max-width 576 per adattarci alla small di bootstrap
@media screen and (max-width: 576px) {
  body {

    /* QUI TUTTO IL CSS*/
    .resizableDialog {
      max-height: 100% !important;
      width: 100% !important;
      height: 100% !important;
    }

    .bacheca {
      width: 100%;
      min-width: unset;
    }

    #login {
      #header {
        padding: 10px 0px;

        .mobile-button {
          margin-top: 0;
          padding: 0;
        }

        .main-nav {
          ul li .support {
            margin-top: -6px;
          }
        }

        &.active-scroll {
          padding: 10px;

          .mobile-button {
            box-shadow: none;
          }
        }
      }

      #container00 {
        .wrap-info {
          background-color: $white;
          padding: 50px;
          border-radius: 16px;
          box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2);
          margin-bottom: 60px;
        }
      }

      #container01 {
        padding-top: 50px;

        #second {
          padding: unset !important;

          img {
            margin-left: auto;
            margin-right: auto;
            display: block;
          }
        }
      }

      #container02 {
        padding-top: 0px;

        .padding {
          padding: 0;
        }

        #first {
          .divCarouselContainer {
            height: auto !important;

            .divItem {
              position: static !important;
            }
          }

          .carouselText {
            width: unset !important;
            left: unset !important;
          }
        }
      }

      #container03 {
        padding: 40px 0 65px;

        .title-container03 {
          h1 {
            line-height: 1em;
          }
        }

        .content-container03 {
          .my-card {
            width: unset;
          }
        }
      }

      #container04 {
        height: unset !important;
        padding: unset !important;
        padding-top: 60px !important;
        padding-bottom: 60px !important;
        text-align: center;

        img {
          margin-top: 40px;
        }
      }

      #container05 {

        &::before {
          display: none !important;
        }

        #second {
          padding: unset !important;

          .img-center {
            margin-top: 25px;
          }

          .wrap-video {
            margin-top: 25px;
            padding: 0;

            youtube-player {
              width: 100%;
              margin: 0 auto;
            }

            iframe {
              width: 100%;
            }
          }
        }
      }

      .padding {
        padding: 0;
      }

      #footer {
        .linkFooter:first-of-type {
          border-left: 1px solid white;
        }

        .logo-footer {
          margin-bottom: 30px;
        }

        .logo-fondazione-agnelli {
          margin-bottom: 30px;
          margin-right: 0;
        }

        .logo-fondazione-de-agostini {
          margin-bottom: 30px;
          margin-right: 0;
        }
      }
    }

    #main-nav {
      display: none !important;
    }

    #main-body {
      margin-left: 0 !important;

      #main.docente {
        padding: 40px 0px !important;

        #secondary-header {
          padding: 0 15px;
        }

        .content {
          padding: 0 15px;
        }

        #dashboard {
          #classi-dashboard {
            .tile-classi-dashboard {
              button.configura-classe-link {
                padding: 0;

                span {
                  vertical-align: middle;
                }

                .icon-classi {
                  &::before {
                    margin-left: 0;
                  }
                }
              }
            }
          }

          #questionari-dashboard {
            .card-questionari-dashboard {
              .info {
                text-align: center;
                align-items: center;
                margin-bottom: 15px;
              }

              .wrap-quest {
                .parallelepipedo {
                  -webkit-transform: unset;
                  transform: unset;
                }

                .quest {
                  /* &:not(:first-child) {
                    margin-top: 15px;
                  } */
                  margin-bottom: 15px;
                }
              }
            }
          }

          #moduli-dashboard {

            .card-moduli {
              .wrap-text {
                .descrizione {
                  margin-bottom: 170px;
                }
              }
            }

            .futuri-button-link {
              padding: 0;
            }

            .card-contribuisci {
              min-height: unset;

              .wrap-content {
                button {
                  position: inherit;
                  width: 100%;
                  margin-top: 12px;
                }
              }
            }
          }
        }

        [class^='parallelepipedo'] {
          -webkit-transform: unset;
          transform: unset;
        }

        #card-modulo-orientativo,
        #card-questionario-non-cognitivo,
        #card-questionario-cognitivo {
          &::before {
            content: unset;
          }

          .card-container {
            &::before {
              content: unset;
            }

            padding: 10px 15px;

            .head {
              padding-left: 0;
              padding-right: 0;
              margin-bottom: 12px;
            }

            .content-card {
              padding-left: 0;
              padding-right: 0;

              .gradi {
                margin-left: 0;
              }
            }

            .footer {
              padding-left: 0;
              padding-right: 0;

              button {
                margin-right: 0;
              }
            }
          }
        }

        #card-questionario-non-cognitivo {
          .card-container {
            .content-card {
              .wrap-text {
                margin-left: 10px;
                margin-right: 0;

                .testo {
                  margin-left: 0px;
                }
              }
            }
          }
        }

        #classe-dettaglio,
        #attivita-classe {
          #banners {
            .banner-notifica {
              .referente {}

              .wrap-content {
                .immagine {
                  display: block;
                  margin: 0 auto;
                }
              }

              .testo {
                margin: 15px 0;
                text-align: center;
              }

              .action-button {
                display: block;
                margin: 0 auto;
              }
            }
          }

          #studenti-classe {
            .input {
              width: 100%;

              .search-field {
                width: 100%;
              }
            }

            .wrap-list {
              overflow-y: unset;
              overflow-x: unset;
              max-height: unset;

              ul li {
                padding: 10px 0px 5px;
                margin-bottom: 15px;

                &.special-button {
                  padding: 0;
                }
              }

              .parallelepipedo {
                -webkit-transform: unset;
                transform: unset;
                width: 100%;
                height: 100%;
                top: 0;

                &::before {
                  content: "";
                  background: url('../../projects/docente/src/assets/images/forma-card-classe.svg')no-repeat right bottom;
                  width: 68px;
                  height: 37px;
                  bottom: 0;
                  right: 0;
                  top: unset;
                }
              }

              .btn-studente {
                margin-right: 0;
                margin-top: 5px;
              }
            }
          }

          #attivita-classe {
            margin-top: 16px;

            .title-questionari {
              h5 {
                margin-top: 5px;
              }

              .futuri-button-link {
                padding: 0;
                margin-top: 5px;
              }
            }

            .card-questionari-cognitivi {
              margin-right: unset;
            }
          }
        }

        #questionari {
          .tabview-custom {
            &.p-tabview {
              .p-tabview-panels {
                padding: 0;

                #card-questionario-non-cognitivo {
                  margin: 0 0 20px 0;
                }
              }
            }
          }

          .p-accordion {
            .p-accordion-content {
              padding: 1.25rem 0;

              .card-questionari-cognitivi {
                margin-right: 0;

                #card-questionario-cognitivo {
                  margin: 0;
                }
              }

            }
          }
        }

        #card-documento {
          .card-contenuti {
            width: unset;
            margin: 0 0 20px 0;
          }
        }

        #card-video {
          .card-contenuti {
            width: 100%;
            min-width: 326px;
            max-width: 364px;

            .thumbnail-video {
              width: 100%;
              max-width: 364px;
            }
          }
        }

        #risultati-grafici {
          .box {
            .valutazione {
              .domanda {
                width: unset;
                margin-bottom: 10px;
              }

              .votazione {
                width: 100%;
              }
            }
          }
        }

        #studente {

          .header-studente {
            .wrap-buttons-studente {

              .bacheca-docenti,
              .button-giudizio {
                margin-left: 0;
                margin-bottom: 15px;
                width: 100%;
              }
            }
          }

          .scuole-successo {
            .wrap-title-successo {
              .title {
                width: unset;
              }
            }

            .cards {
              border-left: unset;
              border-top: 2px solid $fut-grey-neutral;
              margin: 24px 0;
              padding: 28px 0 0;

              .card-success {
                max-width: unset;
                width: 100%;
                padding-right: unset;
              }
            }
          }

          .giudizio-orientativo {
            .wrap-titolo {
              .titolo {
                width: unset;
              }

              border-right: unset;
              padding-bottom: 20px;
              border-bottom: 2px solid #CED6E2;
            }

            .content-giudizio {
              margin: 30px 0;
            }

            .note-docente {
              border-left: unset;
              max-width: unset;
              min-height: unset;
              padding: 0;
            }
          }
        }
      }

      #footer-docente {

        .logo-footer,
        .logo-fondazione-agnelli,
        .logo-fondazione-de-agostini {
          display: block;
          margin: 0 auto;
          margin-bottom: 25px;
        }

        .wrap-rigth {
          margin-right: 0;
        }
      }
    }

    #esprimi-preferenze {
      .secondary-header {
        padding: 15px 20px;
      }

      .content {
        padding: 0;

        .piacerebbe-frequentare {
          .valutazione-interesse {
            .wrap-scuole-interesse {
              .wrap-scuola {
                padding: 18px 16px;

                .domanda {
                  width: 100%;
                  margin-bottom: 24px;
                }

                .wrap-valutazione {
                  width: 100%;
                  padding-right: 8px;
                }
              }
            }
          }

          .buttons {
            button {
              width: 100%;
              margin-bottom: 40px;
            }
          }
        }
      }
    }

    #aggiungi-studente-dialog {
      .content-studente-dialog {
        .wrap-form {
          .field {
            margin-left: 0;
          }
        }
      }
    }

    #scuole-successo-dialog {
      .content-dialog {
        .cards {
          padding: 20px 0;

          .card-success {
            width: 100%;
            max-width: unset;
            padding: 24px 0;
          }
        }
      }
    }

    #configura-classe {
      .step {
        &.due {
          .button-referente {
            &.no {
              margin-top: 16px;
            }
          }
        }
      }
    }

    #second-nav {
      padding: 12px 15px !important;

      .mobile-button {
        display: inline-block !important;
      }

      .profile {
        .text {
          display: none;
        }

        .avatar {
          margin-left: 0;
        }
      }
    }

    #registrazione-studente {
      #codice-studente {
        .wrap-codice {
          .wrap-box {
            .box {
              margin: 10px 10px 15px;
            }
          }
        }

        #infoScuola {
          .title-school {
            font-size: 28px;
          }

          .sub-text {
            margin-top: 50px;
          }
        }
      }
    }

    #registrazione-docente,
    #registrazione-studente {
      #header.active-scrool {
        padding: 30px 0;

        .logo {
          display: block;
          margin: 0 auto;
        }
      }

      #main {
        .container-fluid {
          padding-left: 0 !important;
          padding-right: 0 !important;

          .p-card {
            width: 100% !important;
            margin: 0 !important;
          }
        }
      }

      #footer {
        padding: 10px 0 0 0;

        img {
          margin: 24px 12px 0px 12px;
          display: block;
          margin-left: auto;
          margin-right: auto;
        }
      }

      .logoCarousel button:first-of-type {
        box-shadow: unset !important;
      }

      .logoCarousel button:last-of-type {
        box-shadow: unset !important;
      }

      #info-aggiuntive {
        #materie {
          .wrap-chips-mobile {
            .chips-margin {
              margin: 5px 5px 10px;
            }
          }
        }

        .section-scuola {
          .info-scuola {
            margin-bottom: inherit;
          }

          .text-orange {
            margin-bottom: 1.5rem;
          }
        }
      }

      .bt-registrazione {
        min-width: unset;
      }
    }

    #moduli-orientativi {
      .card-moduli {
        width: 100%;

        .wrap-text {
          margin-right: 0;

          .titolo {
            font-size: 1rem;
          }
        }

        .immagine {
          right: -20%;
          width: 50%;
        }
      }

      .card-contribuisci {
        .wrap-content {
          margin-left: 0;
        }
      }
    }

    #moduli-dettaglio {
      header {
        &#secondary-header {
          .extra-div {
            right: 0;
            width: 33%;
          }

          .wrap-content {
            width: unset;
            margin-right: 0;
          }

          .wrap-button {
            margin-top: 12px;
          }
        }
      }

      .card-contribuisci {
        .wrap-content {
          margin-left: 0;
        }
      }
    }

    #risultati-modulo {
      .wrap-competenze {
        .wrap-valutazione {
          h3 {
            margin-bottom: 15px;
          }

          .form-group-radio {
            margin-bottom: 10px;

            label {
              order: 1;
              margin-left: 10px;
            }
          }
        }
      }

      .button-conferma {
        button {
          width: 100%;
        }
      }

      .risposte-modulo {
        .questionario-modulo {
          .wrap-domande {
            .card-domanda {
              .questionario-radio {
                .wrap-valutazione {
                  .domanda {
                    display: block;
                    margin-bottom: 15px;
                  }

                  .form-group-radio {
                    margin-bottom: 10px;

                    label {
                      order: 1;
                      margin-left: 10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // STUDENTE
    #header-nav {
      padding: 12px 15px;
    }

    #footer-studente {

      .logo-footer,
      .logo-fondazione-agnelli,
      .logo-fondazione-de-agostini {
        display: block;
        margin: 0 auto;
        margin-bottom: 25px;
      }

      .wrap-rigth {
        margin-right: 0;
      }
    }

    #main {
      section.content {
        padding: 12px 15px;
      }
    }

    #card-modulo-orientativo,
    #card-questionario-cognitivo,
    #card-questionario-non-cognitivo {
      margin: 0 10px;
      overflow: hidden;
      border-radius: 12px;

      .card-container {
        padding: 20px;

        .head {
          padding: 0;
        }

        .content-card {
          padding: 0;

          .testo {
            margin-left: 15px;

            &.modulo-testo {
              margin: 0;
            }
          }

          .card-nq-content {
            .icona-materia {
              margin: 0;
            }

            .wrap-text {
              margin: 0;
              margin-left: 15px;

              .testo {
                margin: 0;
              }
            }
          }

          .footer-nq,
          .footer-nq-button {
            margin-top: 15px;
            justify-content: center !important;
            width: 100%;
          }
        }

        .footer {
          padding: 0;
          margin-top: 15px;

          .footer-info {
            margin-bottom: 15px;
            justify-content: center !important;
            width: 100%;
          }

          .footer-button {
            justify-content: center !important;
            width: 100%;
          }
        }
      }



      .parallelepipedo-da-attivare {
        -webkit-transform: skew(0);
        transform: skew(0);
        box-shadow: none;
      }

      .parallelepipedo-da-completare {
        -webkit-transform: skew(0);
        transform: skew(0);
        box-shadow: none;
      }

      .parallelepipedo-da-valutare {
        -webkit-transform: skew(0);
        transform: skew(0);
        box-shadow: none;
      }

      .parallelepipedo-completato {
        -webkit-transform: skew(0);
        transform: skew(0);
        box-shadow: none;
      }

      .parallelepipedo-assegnato {
        -webkit-transform: skew(0);
        transform: skew(0);
        box-shadow: none;
      }
    }

    .content-wrap {
      #card-documento {
        .card-contenuti {
          margin: 0 0 20px;
        }
      }
    }

    #dashboard {
      .bg-risultati {
        padding: 28px 25px !important;
        background: url("../../projects/studente/src/assets/images/bg-shapes-risultati.svg") right 40% top 0% no-repeat $bg-neutral-grey !important;
      }
    }

    #risultati-dashboard {
      .extra {
        display: none;
      }

      .empty-risultati {

        .left-info {
          width: 100%;
          margin: 0;
        }

        .extra-skeleton {
          display: none;
        }
      }

      .cards {
        padding-right: 0;
      }
    }

    #percorso {
      .attivita {
        .questionari {
          margin-bottom: 0;
        }

        .questionari-skeleton {
          .left-info {
            .extra-1 {
              margin-bottom: 20px;

              img {
                margin: 0 auto;
                display: block;
              }
            }

            .info {
              display: block;
              margin: 0 auto;
              text-align: center;
            }
          }

          .extra-2 {
            display: none;
          }
        }
      }

      .wrap-percorso {
        width: 100%;

        .wrap-raod {
          width: 100%;
          min-height: 55px;
          background-size: auto;
          margin: 0 auto;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          .pin {
            position: initial;
            display: inline-block;
            margin: 0 15px 0 0;

            &.fine {
              display: block;

              img {
                width: 50px;
              }
            }

            &.fine.active-fine img {
              padding: 5px;
            }
          }

          .road {
            display: none;
          }
        }

      }

      #banners {
        .banner-notifica {
          .referente {}

          .wrap-content {
            .immagine {
              display: block;
              margin: 0 auto;
            }
          }

          .testo {
            margin: 15px 0;
            text-align: center;
          }

          .action-button {
            display: block;
            margin: 0 auto;
          }
        }
      }

      .scuole-successo {
        .wrap-title-successo {
          .title {
            width: unset;
          }
        }

        .cards {
          border-left: unset;
          border-top: 2px solid $fut-grey-neutral;
          margin: 24px 0;
          padding: 28px 0 0;

          .card-success {
            max-width: unset;
            width: 100%;
            padding-right: unset;
          }
        }
      }

      .giudizio-orientativo {
        .wrap-titolo {
          .titolo {
            width: unset;
          }

          border-right: unset;
          padding-bottom: 20px;
          border-bottom: 2px solid #CED6E2;
        }

        .content-giudizio {
          margin: 30px 0;
        }

        .note-docente {
          border-left: unset;
          max-width: unset;
          min-height: unset;
          padding: 0;
        }
      }

    }

    #main.docente #studente,
    #percorso {
      .preferenza {
        .wrap-preferenza {
          .wrap-titolo {
            border-right: 0;
            margin: 0 0 24px 0;

            .titolo {
              width: 100%;
            }
          }

          .content {
            width: 100%;
            max-width: unset !important;
            margin: 0;
            padding: 28px 0 0;
            border-top: 2px solid $fut-grey-neutral;
          }

          .buttons {
            margin: 24px 0 0;
          }
        }
      }

      .giudizio-orientativo {
        .wrap-titolo {
          .titolo {
            width: unset;
          }

          border-right: unset;
          padding-bottom: 20px;
          border-bottom: 2px solid #CED6E2;
        }

        .content-giudizio {
          margin: 30px 0;
        }

        .note-docente {
          border-left: unset;
          max-width: unset;
          min-height: unset;
          padding: 0;
        }
      }
    }

    #svolgi-questionario {
      .questionario {
        .content-domanda {
          .domanda {
            width: 100%;
            max-width: 100%;
            text-align: left;
          }

          .risposta {
            width: 100%;

            .form-group {
              display: flex;
              text-align: left;
              margin-left: 0;
              margin-bottom: 15px;
              min-width: 95%;

              label {
                order: 1;
                min-width: 90%;
                margin-left: 10px;
                margin-bottom: 0px;
              }

              &.followup {
                display: flex;

                label {
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }

    #svolgi-modulo {
      .questionario {
        .wrap-domande {
          padding: 20px 20px 10px;

          .card-domanda {
            .questionario-radio {
              .wrap-radio {
                padding-left: 0;
                margin-top: 20px;

                .input-radio-sv {
                  text-align: left;
                  margin-left: 0;
                  margin-bottom: 15px;
                  min-width: 95%;

                  label {
                    order: 1;
                    min-width: 100%;
                    margin-left: 10px;
                    margin-bottom: 0px;
                  }
                }
              }

              .dom .domanda {
                display: block;
              }
            }
          }
        }
      }
    }

    #risultati-studente {
      .accordion {
        padding: 0 10px;
      }

      #abilita-inclinazioni {
        .tabs {
          padding: 0 20px;
        }

        .content {
          padding: 0 20px;

          &.box-blue {
            padding: 25px 20px;

            .card-box {
              .motivazione {
                margin-bottom: 30px;
              }
            }
          }

          .box.preferenze {
            .subtitle {
              text-align: center;
            }

            .materie {
              min-height: 226px;

              .materia {
                &.prima {
                  width: 160px;
                  height: 160px;
                }

                &.seconda {
                  width: 130px;
                  height: 130px;
                }
              }
            }
          }
        }
      }
    }

    #contenuti {
      .wrap-card .card .titolo {
        bottom: 20%;
        left: 10%;
      }

      .button-cancella {
        width: 100%;
        display: block;
        margin-bottom: 10px;
      }
    }

    #profilo-studente {
      .email-genitori-wrap {
        .second-line {
          margin-right: 0;

          input {
            width: 100%;
            margin-right: 0;
          }
        }
      }
    }

    #profilo-docente {
      .cambioPassword, .cambioEmail {
        margin-top: 10px;
      }
    }

    #svolgi-prova {
      #header-svolgi {
        padding: 10px 0px 5px;

        .back-title {
          margin-bottom: 0;

          .title-svolgi {
            display: none;
          }
        }

        .save-info {
          .durata-info {
            margin: 0;
          }
        }
      }
    }

    #esprimi-giudizio-orientativo {

      .bacheca-docenti {
        margin-top: 20px;
      }

      .preferenze-scuole {
        .selected-scuole {
          .scuola {
            .wrap-card {
              width: 100%;
              min-width: 100%;
              margin-bottom: 20px;
            }
          }
        }
      }

      .filtri-scuole {
        .wrap-input {
          max-width: unset;
          margin: 5px 0;
        }
      }

      .scuole {
        .scuola {
          max-width: unset;
        }
      }

      .save {
        button {
          width: 100%;
        }
      }
    }
  }
}

// Aumentato il max-width 576 per adattarci alla small di bootstrap
@media screen and (min-width: 576px) and (max-width: 850px) {
  body {}
}
