/* Variabili Generali =================================================== */
$font-color: #141414;
$bg-color: #fafafa;
$white: #fff;
$neutral-grey: #E9EFF6;
$greenSuccess: #13c55a;
$warning: #F6D61C;
$tertiary: #F25A50;

/* Variabili Tema Futuri ================================================ */
$bg-dashboard: #f8f9fc;
$fut-lightBlue: #1C5CCC;
$fut-blue: #0e2e66;
$fut-darkorange: #B2562D;
$fut-orange: #f48756;
$fut-orange-shadow: rgba(244, 135, 86, 0.16);
$fut-lightorange: #FFB08C;
$border-button: #ced6e2;
$input-color: #ced6e2;
$bg-grey: #e9eff6;
$bg-grey-registrazione: #F1F5FA;
$ico-yellow: #ffe54d;
$ico-aquamarine: #9de5d0;
$shadow-color: #e9ecf2;
$font-size-menu: 18px;
$padding-landing: 50px 100px;
$bg-landing-futuri: #e3fff7;
$redError: #f25a50;
$bg-dialog-grey: #F8F9FC;
$bg-login-grey: #FCFCF8;
$fut-tertiary-pink-light: #FFEDF1;

/* Variabili Tema Docente ================================================ */
$bg-main-nav: #fff;
$bg-main-body: #F8F9FC;
$border-radius-nav: 8px;
$border-radius: 12px;
$color-referente: #0EB4A1;
$bg-questionari: #EEFFFA;
$border-questionari-da-attivare: #FFFFFF;
$border-questionari-da-completare: #7CA2E5;
$border-questionari-da-valutare: #DBCBF2;
$border-questionari-completati: #0EB4A1;
$border-questionari-in-svolgimento: #F8BECA;
$bg-da-completare: #E9EFF7;
$bg-da-valutare: #DBCBF2;
$bg-in-svolgimento: #FFEDF1;
$fut-purple: #8054BF;
$bg-neutral-grey: #F1F5FA;
$semantics-error: #E93E32;
$bg-studenti-classe: #E9EFF7;
$fut-grey-no-studenti: #98A2B7;
$fut-grey-neutral: #CED6E2;
$fut-in-svolgimento: #A66F7A;
$bg-conferma-dialog-messaggio: #E9EFF7;
$fut-grey-neutral-50: #98A2B7;
$fut-grey-neutral-30: #E9EFF7;
$fut-grey-neutral-60: #626F87;
$fut-grey-neutral-70: #434E64;

/* Variabili Tema Studente ================================================ */
$fut-tertiary-petrol: #0EB4A1;
$bg-box-blue: #4174CD;
$bg-svolgi: #F8F9FC;
$bg-header-svolgi: #F1F5FA;
$bg-gradi: #E9EFF7;
$bg-intestazione-ligthblue: #E9EFF7;
$bg-content-domande: #F8F9FC;
$bg-header-esprimi-preferenze: #F1F5FA;